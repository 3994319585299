import { Card } from "ui-neumorphism";

export let EducationCard = () => {
  return (
    <>
      <h2 className="h2-cv">Education</h2>

      <Card flat>
        <div style={{ padding: "15px 15px 15px 15px" }}>
          <h3>
            09/2017-03/2022 |{" "}
            <span>
              <a target="_blank" href="https://www.mcgill.ca/chemistry/">
                McGill Univeristy
              </a>{" "}
              | Montreal, Qc, Canada
            </span>
          </h3>

          <section>
            <b>PhD, Chemistry and Environmental Engineering</b>
            <ul>
              <li>
                Co-supervised by Prof. Audrey Moores and Prof. Subhasis Ghoshal
              </li>
              <li>
                First student in McGill Sustainablitiy Systems Initative (MSSI)
                <li>
                  Coordinated student-run events on soft skills, sustainability,
                  and careers
                </li>
              </li>
              <li>
                Coordinated with three other professors & thier students to
                complete projects
                <li>Two first author publications, more on the way</li>
              </li>
            </ul>
          </section>
        </div>

        <div style={{ padding: "0px 15px 15px 15px" }}>
          <h3>
            09/2016–08/2017 |{" "}
            <span>
              <a target="_blank" href="https://www.unige.ch/efficience/">
                UNIVERSITY OF GENEVA
              </a>{" "}
              | Geneva, Switzerland
            </span>
          </h3>

          <section>
            <b>Fulbright Scholar</b>
            <ul>
              <li>
                Ran life cyle assessment on bio-based and biodegradable plastics
              </li>
              <li>
                Coordinate with four stakeholders whose processes were
                represented in life cycle
              </li>
            </ul>
          </section>
        </div>

        <div style={{ padding: "0px 15px 15px 15px" }}>
          <h3>
            09/2012–06/2016 |{" "}
            <span>
              <a target="_blank" href="https://muse.union.edu/aerogels/">
                UNION COLLEGE
              </a>{" "}
              | Schenectady, NY
            </span>
          </h3>

          <section>
            <b>BSc, Chemistry and Environmental Science</b>
            <ul>
              <li>
                Materials chemistry research in the aerogel lab & Primaloft
              </li>
              <li>
                Literature review of plastics recycling in life cycle
                assessments
              </li>
            </ul>
          </section>
        </div>
      </Card>
    </>
  );
};
