import { Card } from "ui-neumorphism";

export let SkillsCard = () => {
  return (
    <>
      <h2 className="h2-cv">Skills</h2>
      <Card flat>
        <div style={{ padding: "0 15px 15px 15px" }}>
          <h3 style={{ padding: "15px 0 0 0" }}>Grant Writing</h3>

          <section>
            <b>Clark SELF Fellowship, 2021 </b>
            <ul>
              <li>$2k scholarship to attend McGill's mini-MBA</li>
            </ul>

            <b>Lawrence Light Fellowship in Sustainability, 2020</b>
            <ul>
              <li>$3k scholarship to run an alternatives assessment </li>
            </ul>

            <b>FRQNT Grant, 2018-2022</b>
            <ul>
              <li>
                $20k/year scholarship to conduct PhD in transformations of
                emerging materials
              </li>
            </ul>

            <b>Fulbright Fellowship, 2016</b>
            <ul>
              <li>$50k scholarship for LCA research in Geneva </li>
            </ul>
          </section>
          <h3 style={{ padding: "15px 0 0 0" }}>Business Courses</h3>

          <section>
            <b>McGill Desautels Exceutive Development Course</b>
            <br />
            <b>Basic Business Skills for Non-Business Graduate Students</b>
          </section>

          <h3 style={{ padding: "15px 0 0 0" }}>Leadership</h3>

          <section>
            <b>McGill Sustainability Systems Initative</b>
            <ul>
              <li>Student cooredinator of materials section</li>
              <li>
                Organize quarterly events on sustainability & soft skills{" "}
              </li>
              <li>Develop sustainability checklist for future researchers </li>
            </ul>
            <b></b>
            <ul></ul>
          </section>
        </div>
      </Card>
    </>
  );
};
