import { Card, Button, Divider } from "ui-neumorphism";
import React, { useState } from "react";
import { ProfessionalCard } from "./ProfessionalCard.react";
import { EducationCard } from "./EducationCard.react";
import { SkillsCard } from "./SkillsCard.react";

let ContentDiv = (props) => {
  if (props.clicked === "expertise") {
    return <ProfessionalCard />;
  } else if (props.clicked === "education") {
    return <EducationCard />;
  } else if (props.clicked === "skills") {
    return <SkillsCard />;
  }
  return <Card>{props.clicked}</Card>;
};

export let Resume = () => {
  const [clicked, setClicked] = useState("education");

  return (
    <Card flat style={{ textAlign: "left", padding: "10px" }}>
      <div style={{ width: "100%" }}>
        <div
          style={{
            /*  marginTop: "25px", */ alignItems: "center",
            justifyContent: "center",
          }}
        >
          {["education", "expertise", "skills"].map((i) => {
            return (
              <Button
                text
                size="small"
                key={i}
                active={clicked === i ? true : false}
                onClick={() => setClicked(i)}
                bgColor={clicked === i ? "#d5f2e8" : ""}
                style={{ marginRight: "10px" }}
              >
                {i}
              </Button>
            );
          })}
        </div>
      </div>
      <ContentDiv clicked={clicked} />
      <Divider dense />
      <br />
      <div>
        For a full CV, click to{" "}
        <a href="AudeBechu_Resume.pdf" target="_blank">
          view
        </a>{" "}
        or
        <a href="AudeBechu_Resume.pdf" download>
          {" "}
          download.
        </a>
      </div>
    </Card>
  );
};
