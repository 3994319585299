import { Card } from "ui-neumorphism";

export let ProfessionalCard = () => {
  return (
    <>
      <h2 className="h2-cv">Expertise </h2>
      <Card flat>
        <div style={{ padding: "15px 15px 15px 15px" }}>
          <h3>
            Alternatives Assessment{" "}
            <ul>
              <li>
                <span>
                  <a
                    target="_blank"
                    href="https://pubs.acs.org/doi/abs/10.1021/acssuschemeng.1c04909"
                  >
                    ACS Sustainable Chemistry & Engineering
                  </a>
                </span>
              </li>
            </ul>
          </h3>
        </div>

        <div style={{ padding: "15px" }}>
          <h3>
            Transformations of emerging nanomaterials{" "}
            <ul>
              <li>
                <span>
                  <a
                    target="_blank"
                    href="https://pubs.acs.org/doi/abs/10.1021/acssuschemeng.1c04909"
                  >
                    ACS Journal of Applied Nanomaterials
                  </a>{" "}
                </span>
              </li>
            </ul>
          </h3>
        </div>

        <div style={{ padding: "15px 15px 30px 15px" }}>
          <h3>
            Life Cycle Assessment{" "}
            <ul>
              <li>
                <span>
                  <a
                    target="_blank"
                    href="https://onlinelibrary.wiley.com/doi/abs/10.1002/bbb.1849"
                  >
                    Biofeuls, Bioproducts, and Biorefining
                  </a>{" "}
                </span>
              </li>
            </ul>
          </h3>
        </div>
      </Card>
    </>
  );
};
