import "./App.css";
import React, { useState } from "react";

import { Button, Card } from "ui-neumorphism";
import "ui-neumorphism/dist/index.css";
import { Contact } from "./Contact.react";
import { About } from "./About.react";
import { Resume } from "./Resume.react";

import { overrideThemeVariables } from "ui-neumorphism";

let ContentDiv = (props) => {
  if (props.clicked === "about") {
    return <About />;
  } else if (props.clicked === "contact") {
    return <Contact />;
  } else if (props.clicked === "resume") {
    return <Resume />;
  }
  return <Card>{props.clicked}</Card>;
};

function App() {
  const [clicked, setClicked] = useState("about");

  // https://www.npmjs.com/package/ui-neumorphism
  overrideThemeVariables({
    "--light-bg": "#e3fdf1", // aude #e3fdf1 //#ade6d5
    "--light-bg-dark-shadow": "#86aea2", // aude
    "--light-bg-light-shadow": "#9bcabc", // aude
    "--dark-bg": "#292E35",
    "--dark-bg-dark-shadow": "#21252a",
    "--dark-bg-light-shadow": "#313740",
    "--primary": "#482bef", //'"#8672FB",
    "--primary-dark": "#4526f9",
    "--primary-light": "#c7befd",
  });

  return (
    <div className="App">
      <header className="App-header">
        <h1 style={{ paddingTop: "50px", paddingBottom: "25px" }}>
          Aude Bechu
        </h1>
        <div>
          {/* {["about", "contact"].map((i) => { */}
          {["about", "resume", "contact"].map((i) => {
            return (
              <Button
                key={i}
                text={clicked === i ? true : false}
                onClick={() => setClicked(i)}
                style={{ margin: "15px" }}
              >
                {i}
              </Button>
            );
          })}
        </div>
      </header>
      <div className="Content">
        <Card style={{ padding: "25px" }}>
          <ContentDiv clicked={clicked} />
        </Card>
      </div>
    </div>
  );
}

export default App;
