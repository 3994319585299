import { Card } from "ui-neumorphism";

let StyledCard = (props) => {
  return (
    <p style={{ marginTop: "10px" }}>
      <a href={props.href}>{props.text}</a>
    </p>
  );
};

export let Contact = () => {
  return (
    <Card flat style={{ textAlign: "left", padding: "10px" }}>
      <div style={{ width: "100%" }}>
        <p>
          I enjoy working with others who are passionate about implementing safe
          and sustainable solutions.
        </p>
        <p style={{ marginTop: "10px" }}>
          Happy to speak about new and exciting opportunities!
        </p>
        <p style={{ marginTop: "30px" }}>Feel free to reach out:</p>
        <StyledCard
          href="mailto:aude.bechu@gmail.com"
          text="aude.bechu (@) gmail.com"
        />{" "}
        <StyledCard
          target="_blank"
          href="https://www.linkedin.com/in/aude-bechu-015422ab/?originalSubdomain=ca"
          text="/in/audebechu"
        />{" "}
      </div>
    </Card>
  );
};
