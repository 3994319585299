import me from "./me.jpeg";
import { Card } from "ui-neumorphism";
import React from "react";

export let About = () => {
  return (
    <Card flat style={{ textAlign: "left", padding: "10px" }}>
      <div style={{ width: "100%" }}>
        <img src={me} alt="profile_pic" className="Prof-pic" />

        <p>
          Hi, I'm Aude Bechu, an interdisciplinary researcher focused on safe
          and sustainable materials. <br />
          <br /> During my PhD, I explored the environmental transformations and
          toxicological profiles of emerging materials, as well as
          sustianability assessments (life cycle and alternatives). My recent
          publications on these topics are available on{" "}
          <a
            target="_blank"
            href="https://scholar.google.com/citations?user=bUDmlq4AAAAJ&hl=en&oi=ao"
          >
            Google Scholar
          </a>
          .
          <br />
          <br />
          I'm interested in exploring how safe & sustainable science impacts (1)
          the development of materials in industry and (2) the regulation of
          materials by governments. <br />
          <br /> In my free time, I like to cook and enjoy the outdoors. <br />
        </p>
      </div>
    </Card>
  );
};
